import logo from "./logo.svg";
import "./App.css";
import "./assets/cassino.css";
import "./assets/websocket.css";
import "./assets/FlipCountdown.css";
import "./theme/greentheme.scss";
import "./app.scss";
import "./assets/iframsocket.css"

import LoginForm from "./component/pages/login";
import RouteConfig from "./routesconfig";
import CommonLoader from "./component/common/lodder";
import { useDispatch, useSelector } from "react-redux";
import SnackbarAlert from "./component/common/snackbar";
import { useEffect, useState } from "react";
import { FetchUserDetails } from "./api/cassino/cassinogame";

function App() {

  const loading = useSelector((state) => state.auth.loading);
  const myTheme = useSelector(state => state.auth.theme);
    

  useEffect(() => {
    // Update the data-theme attribute on the HTML tag
    document.documentElement.setAttribute("data-theme", myTheme);
    // Store the myTheme in localStorage
    localStorage.setItem("theme", myTheme);
  }, [myTheme]);

  return (
    <>
      <div className={myTheme !== "light" ? "dark" : ""}>
        <CommmonComponentProvider />
        <RouteConfig />
        {loading && <CommonLoader />}
      </div>
    </>
  );
}

export default App;

const CommmonComponentProvider = () => {
  return (
    <>
      <SnackbarAlert />
    </>
  );
};
