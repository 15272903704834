import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
const PlacebetCasino = ({ placebet, setPlacebet, stake, betprofit,setStake, handlingbet, betSubmitConform,betnation,odds,betReset,onClose,loading }) => {
  
  const buttonValues = [
    [
      { label: "₹ 200", value: 200 },
      { label: "₹ 500", value: 500 },
      { label: "₹ 1K", value: 1000 },
      { label: "₹ 5K", value: 5000 },
      { label: "₹ 10K", value: 10000 },
    ],
    [
      { label: "₹ 20K", value: 20000 },
      { label: "₹ 25K", value: 25000 }, 
    ]
  ];

  return (
    <>

      <div className={`card placebet ${placebet ? "" : "d-none"}`}>
      <div className="place-bet-h cmc">
                        <span>Place Bet</span>
                        <span onClick={onClose}><CloseIcon/></span>
                      </div>
        <div className="card-body ">
          <table className="table table-bordered text-center">
            <thead>
              <tr><th>Bet for</th><th>Odds</th><th>Stake</th><th>Profit</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>
               
                  <span style={{ fontSize: '16px' }}>{betnation}</span>
                </td>
                <td>
                  <input type="number" value={odds} className="form-control form-control-sm text-center" readOnly style={{ width: '100px' }} />
                </td>
                <td>{stake}</td>
                <td style={{ color: 'green' }}>{betprofit}</td>
              </tr>
            </tbody>
          </table>
          <div className="card-footer d-flex justify-content-between">
            <input
              autoFocus={true}
            style={{
              width:'100px',
              height:'40px'
            }}
            placeholder='amount'
            value={stake}
            type='number'
            // max="25000" // Limit input to a maximum of 25,000
            onChange={(e) => {
              const value = e.target.value;
              // Only update the stake if it's a whole number and does not contain a decimal point
              if (/^\d*$/.test(value) && Number(value) <= 150000) {
                setStake(value);
              }
            }}
             />
          <button  disabled={loading} style={{height:'36px'}} onClick={betSubmitConform} className="btn btn-success">Submit</button>
          <button style={{height:'36px',padding:'0px 4px'}}  onClick={betReset} className="btn btn-danger">Reset</button>
        </div>
          <div>
            {buttonValues.map((group, index) => (
              <div key={index} className="btn-group btn-group-sm d-flex justify-content-between" role="group" style={{ height: '40px' }}>
                {group.map(({ value, label }, btnIndex) => (
                  <button
                 
                  onClick={() => handlingbet(value)} key={btnIndex} className="btn btn-secondary">                 
                    {label}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
       
      </div>
  
    </>
  );
};

export default PlacebetCasino;
