import LiveTvIcon from "@mui/icons-material/LiveTv";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { MatchSummary } from "../common/scorecardCard";
import { BackButton } from "../common/backbutton";
import { useNavigate, useParams } from "react-router-dom";
import { home, inplay } from "../../routesconfig/constant";
import { useEffect, useState } from "react";
import PlaceSassionBet, {
  GetFancyresult,
  GetMatchsList,
  GetscorecardLink,
} from "../../api/sassinmatch/sassionbet";
import GetCaller from "../../service/apiServices";
import { GetFancyBet } from "../../service/apiconst";
import { connect, useDispatch } from "react-redux";
import { BookmakerPlusMinus, useMatchSessionLive } from "../../api/sassinmatch/useMatchSessionLive";
import React, { useRef } from "react";
import { format } from "date-fns"; // Import format from date-fns
import { toZonedTime } from "date-fns-tz";
import HeaderNotify from "../common/headerNotify";
import { FetchUserDetails } from "../../api/cassino/cassinogame";
import { setSnackbaralert, setUserDetail } from "../../store/feature/authSlice";
import nodata from "../../assets/images/nodata.jpg";
import { pollingValue } from "../../service/utilities/poolingutils";
import { io } from "socket.io-client";
import BetDialog, { BetSessionDialog } from "../common/dialog";
import { Switch } from "@mui/material";
import { roundToDecimals } from "../../service/utilities/roundDecimal";
const InplaySession = () => {
  const welcomeRef = useRef(null);
  const dispatch = useDispatch();
  const scrollToContent = () => {
    // Scroll to the target element
    if (welcomeRef.current) {
      welcomeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const [amountInput, setAmountinput] = useState("");
  const navigate = useNavigate();
  const [selectedSession, setSelectedSession] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);
  const [betodds, setBettingOdds] = useState(null);
  const [betMode, setBetMode] = useState("");
  const [matchdetail, setMatchdetails] = useState([]);
  const [selectedTossSessionIndex, setSelectedTossSessionIndex] =
    useState(null);
  const [selectedSessionIndex, setSelectedSessionIndex] = useState(null);
  const [matchsession, setMatchSassiondata] = useState();
  const [fancydata, setFancyData] = useState([]);
  const [fancytype, setFancyType] = useState([]);
  const { matchId } = useParams();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [streemhide, setStreenHide] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [livescorecard, setScorecardLink] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedfs, setCheckedfs] = useState(false);
  
  const [scorecardbool, setScorecardtrue] = useState(true);
  const [MatcheventId, setEventId] = useState();
  const [bokkmakerpm, setPlusMius] = useState();
  const [bookmakerPMData, setBooKmarkBets] = useState([]);
  const [pmposition1, setposition1] = useState(0);
  const [pmposition2, setposition2] = useState(0);
  const [pmposition3, setposition3] = useState(0);
  

  const handleClose = () =>{
    setOpen(false);
    setAmountinput('')

  }
    
 




  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  const handleTogglefs = (event) => {
    setCheckedfs(event.target.checked);
  };

  

  const SOCKET_SERVER_URL = "https://automaticapiserver.bbet247.net";
  const PASS_YOUR_EVENT_ID = matchId; // Replace with your actual event ID

  useEffect(() => {
    if (!pollingValue) {
      const socket = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
      socket.on("connect", () => {
        socket.emit("addOnEVENT", PASS_YOUR_EVENT_ID);
      });

      socket.on("updatedData", (data) => {
        // Do work with your data

        setMatchSassiondata(data.data);
      });

      // Error handling
      socket.on("connect_error", (err) => { });

      socket.on("error", (err) => { });

      // Cleanup function to disconnect the socket when the component unmounts
      return () => {
        socket.disconnect();
      };
    }
    {
      const interval = setInterval(sassionrefatch, 1500);
      return () => clearInterval(interval);
    }
  }, []);

  const { response: livesassion, refetch: sassionrefatch } =
    useMatchSessionLive(matchId);




  const sassiondata = pollingValue ? livesassion : matchsession;

  const livesassiondata = sassiondata?.data || [];
  const bookMakerArray = livesassiondata.filter(
    (item) => item.type === "BookMaker"
  );
  const tossArray = livesassiondata.filter((item) => item.type === "Toss");
  const fancyArray = livesassiondata.filter((item) => item.type === "Session");

  const { response: matchlist } = GetMatchsList();

  const matchdetails = matchlist?.data?.filter(
    (item) => String(item.marketId) === String(matchId)
  );

  const streamingLink =
    matchdetails?.length > 0 && matchdetails[0]?.streamingLink;
  const scorecardLink =
    matchdetails?.length > 0 && matchdetails[0]?.scorecardLink;
  const eventId = matchdetails?.length > 0 && matchdetails[0]?.id;



  const { response: plusminusdata, } = BookmakerPlusMinus(eventId);

  const bookmakerpm = plusminusdata?.data



  useEffect(() => {
    if (typeof streamingLink === "string" && scorecardLink.startsWith("http")) {
      setVideoLink(streamingLink);
    }
  }, [streamingLink]);

  const { response: responseScorecard } = GetscorecardLink(matchId);

  const scorelink = responseScorecard?.data;

  useEffect(() => {
    if (
      (typeof scorecardLink === "string" && scorecardLink.startsWith("http")) ||
      (typeof scorelink === "string" && scorelink.startsWith("http"))
    ) {
      if (scorecardLink.startsWith("http")) {
        setScorecardLink(scorecardLink);
      } else if (scorelink.startsWith("http")) {
        setScorecardLink(scorelink);
      }
    } else {
      setScorecardLink("");
      setScorecardtrue(false);
    }
  }, [scorecardLink, scorelink]); // Dependencies

  const { response: responseprofile, refetch: Refetchprofile } =
    FetchUserDetails();
  const userdetails = responseprofile?.data;

  dispatch(setUserDetail(userdetails));

  useEffect(() => {
    // const intervalId = setInterval(sassionrefatch, 1500);
    const intervalprofile = setInterval(Refetchprofile, 5000);

    return () => clearInterval(intervalprofile);
  }, []);

  const handleClick = () => {
    navigate(inplay);
  };

  const handleSessionClick = (
    session,
    mode,
    betvolumn,
    betOdds,
    index,
    ftype
  ) => {

    setOpenDialog(true);
    handleOpen();
    scrollToContent();
    setSelectedSession(session);
    setSelectedRate(betOdds);
    setBettingOdds(betvolumn);
    setSelectedSessionIndex(index === selectedSessionIndex ? null : index);
    setSelectedTossSessionIndex(
      index === selectedTossSessionIndex ? null : index
    );
    setBetMode(mode);

    setFancyType(ftype);
    if (ftype === "Toss") {
      setSelectedTossSessionIndex(index); // Set the index for Toss section
      setSelectedSessionIndex(null); // Ensure the Session section closes
    } else if (ftype === "Session") {
      setSelectedSessionIndex(index); // Set the index for Session section
      setSelectedTossSessionIndex(null); // Ensure the Toss section closes
    }
  };


  const handleAmountChange = (event) => {
    if (event && event.target) {
      let value = parseInt(event.target.value, 10);

      // Check for the special condition: fancytype === "BookMaker" && betMode === "No"
      if (fancytype === "BookMaker" && betMode === "No") {
        // If the value is less than or equal to 200000, set it to 200000
        if (value > 100000) {
          value = 100000;
        }
      } else {
        // Otherwise, apply the max limit of 25000
        if (value > 50000) {
          value = 50000; // Set to maximum if too high
        }
      }

      setAmountinput(value);
    }
  };

  const fanctBetResult = (item) => {
    GetCaller({
      API: GetFancyBet,
      dispatch: (e) => { },
      item: item,
      callback: ({ response }) => {
        const fancydata = response?.data?.data;
        setFancyData(fancydata);
      },
    });
  };

  useEffect(() => {
    const intervalfancy = setInterval(fancyRefetch, 3000);
    return () => clearInterval(intervalfancy);
  }, [eventId]);

  const { response: fancyResult, refetch: fancyRefetch } =
    GetFancyresult(eventId);

  const data1 = fancyResult?.data?.filter((item) => item.completed === true);

  const SessionBets = fancyResult?.data?.filter(
    (item) => item.completed === false && 
              (item.oddType === "Session")
  );
  const TossBets = fancyResult?.data?.filter(
    (item) => item.completed === false && 
              (item.oddType === "Toss")
  );

  const BookmakerBets = fancyResult?.data?.filter(
    (item) => item.completed === false && item.oddType === "BookMaker"
  );

// {
//     "session": "AUSTRALIA",
//     "oddType": "BookMaker",
//     "run": 0,
//     "rate": 0.52,
//     "result": null,
//     "amount": 200,
//     "mode": "No",
//     "profitAndLoss": null,
//     "createdDateTime": "2024-12-27T07:23:11.4914049",
//     "completed": false,
//     "rejected": false,
//     "plusMinusPosition": null
// }
 


useEffect(()=>{
if(BookmakerBets){
  const BookmkerList = BookmakerBets?.map((res, index) => ({
...res,
Bloss:  -(res.amount * res.rate), 
Bprofit: res.mode === "No" ? res.amount : res.amount * res.rate,
p1: res.session === "THE DRAW" ? (res.mode === "No" ? res.amount : -res.amount) :  (res.mode === "No" ?  -(res.amount * res.rate) : (res.amount * res.rate)),
p2:   res.session === "THE DRAW" ? (res.mode === "No" ? res.amount : -res.amount) :  (res.mode ==="No" ? res.amount : -(res.amount)),
p3: res.session === "THE DRAW" ? (res.mode === "No" ? -(res.rate * res.amount)  : res.rate * res.amount) : (res.mode ==="No" ? res.amount : -(res.amount)),
  }))


setBooKmarkBets(BookmkerList)

}

},[BookmakerBets])

console.log("bookmakerlist",bookmakerPMData)

  const costTotal = data1?.reduce((acc, item) => {
    // Ensure profitAndLoss is a number, defaulting to 0 if undefined or null
    return acc + (item?.profitAndLoss ? parseFloat(item?.profitAndLoss) : 0);
  }, 0);

  const { response, loading, submit } = PlaceSassionBet();

  const galaSession = SessionBets?.find(
    (item) => item?.session === selectedSession?.name
  );


  const handelPlaceBet = () => {
    debugger;

    fancytype === "Session" && handelPlaceSessionBet()
    fancytype === "BookMaker" && handelPlaceBookmaakerBet()
    fancytype === "Toss" && handelPlaceTossBet()


  };

  const handelPlaceSessionBet = () => {

    if (selectedSession && amountInput > 99 && amountInput <= selectedSession.maxBetSize) {
      // Define the bypass condition
      const bypassCoinCheck =
        fancytype === "Session" &&
        betMode === "No" &&
        winningamount > 99;
  
      // Check if the betMode is "No" or "Yes" and validate accordingly
      if (
        (betMode === "No" && amountInput > 99) || // "No" mode requires winning amount > 99
        (betMode === "Yes" && amountInput > 99)  // "Yes" mode requires input amount > 99
     
      ) {
        const betData = {
          eventId: eventId.toString(),
          sessionId: String(selectedSession.selectionId),
          session: selectedSession.name,
          run: fancytype === "Toss" ? betodds * 100 : parseFloat(betodds),
          rate: parseFloat(selectedRate),
          amount: amountInput, // Use amountInput for other cases
          mode: betMode,
          oddType: fancytype,
        };
  
        // Submit the betData
        submit(betData, (response) => {
          if (response) {
            fancyRefetch(response?.data?.id);
            Refetchprofile();
            setAmountinput(""); // Clear input
            setSelectedSession(null); // Reset selection
            setBettingOdds(null);
            setBetMode("");
          }
        });
      } else {
        // Show error if conditions are not met
        dispatch(
          setSnackbaralert({
            active: true,
            message:
                "Winning amount must be greater than 99 for 'No' bet mode.",
            type: "error",
          })
        );
      }
    } else {
      // Show error if the amount is invalid
      dispatch(
        setSnackbaralert({
          active: true,
          message: "Amount must be between 100 and session limit.",
          type: "error",
        })
      );
    }
  
    // Reset UI states
    setSelectedSession(null);
    handleClose();
    setOpenDialog(false);
  };
  

  const handelPlaceBookmaakerBet = () => {
    if (selectedSession) {
      if (betMode === "No" && amountInput <= 99) {
        // Show alert if the "No" bet mode has winning amount <= 99
        dispatch(
          setSnackbaralert({
            active: true,
            message: "Minimum Amount must be greater  100",
            type: "error",
          })
        );
      } else if (betMode === "Yes" && amountInput <= 99) {
        // Show alert if the "Yes" bet mode has amount input <= 99
        dispatch(
          setSnackbaralert({
            active: true,
            message: "Amount must be greater 100",
            type: "error",
          })
        );
      } else {
        // Proceed with placing the bet if conditions are met
        const betData = {
          eventId: eventId.toString(),
          sessionId: String(selectedSession.selectionId),
          session: selectedSession.name,
          run: fancytype === "Toss" ? betodds * 100 : parseFloat(betodds),
          rate: parseFloat(selectedRate),
          amount: amountInput, // Use winningamount for "No" mode, otherwise amountInput
          mode: betMode,
          oddType: fancytype,
        };
  
      // Submit the betData
        submit(betData, (response) => {
          if (response) {
            fancyRefetch(response?.data?.id);
            Refetchprofile();
            setAmountinput("");
            setSelectedSession(null);
            setBettingOdds(null);
            setBetMode("");
          }
        });
      }
    } else {
      // If no session is selected
      dispatch(
        setSnackbaralert({
          active: true,
          message: "Please select a session before placing a bet.",
          type: "error",
        })
      );
    }
    setSelectedSession(null);
    handleClose();
    setOpenDialog(false);
  };
  

  const handelPlaceTossBet = () => {

    if (selectedSession && amountInput > 99 && amountInput <= 25000) {

      if (userdetails.chips >= 100) {
        const betData = {
          eventId: eventId.toString(),
          sessionId: String(selectedSession.selectionId),
          session: selectedSession.name,
          run: fancytype === "Toss" ? betodds * 100 : parseFloat(betodds),
          rate: parseFloat(selectedRate),
          amount: amountInput, // Use amountInput for other cases
          mode: betMode,
          oddType: fancytype,
        };

        // Submit the betData
        submit(betData, (response) => {
          if (response) {
            fancyRefetch(response?.data?.id);
            Refetchprofile();
            setAmountinput(""); // Clear input
            setSelectedSession(null); // Reset selection
            setBettingOdds(null);
            setBetMode("");
          }
        });
      } else {
        dispatch(
          setSnackbaralert({
            active: true,
            message: "Insufficient coins to place a bet.",
            type: "error",
          })
        );

      }
    } else {
      dispatch(
        setSnackbaralert({
          active: true,
          message: "You amount must be 100 to Session limit",
          type: "error",
        })
      );


    }

    setSelectedSession(null);
    handleClose();
    setOpenDialog(false);
  }


  const handelCancelkBet = () => {
    setSelectedSession(null);
  };

  const winningamount = roundToDecimals(amountInput * parseFloat(selectedRate));

  const fancy = false;

  const buttonValuesi = [
    {
      label: "₹ 100",
      value: 100,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 200",
      value: 200,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 500",
      value: 500,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 1000",
      value: 1000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 5K",
      value: 5000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
  ];

  const buttonValues = [
    {
      label: "₹ 10K",
      value: 10000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 20K",
      value: 20000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 25K",
      value: 25000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 0",
      value: 0,
      style: { backgroundColor: "red", padding: "4px 0px" },
    }, // Custom style for this button
  ];

  const handleCancelBet = () => {
    setOpenDialog(false);
    setAmountinput("")
  };

  const handelAmountclick = (amountvalue) => {
    setAmountinput(amountvalue);
  };

  const handleInputChange = (event) => {
    // Ensure event and event.target exist before accessing
    if (event && event.target) {
      setAmountinput(event.target.value);
    }
  };


  const odd1st = bookMakerArray[0]?.oddList[0]
  const odd2nd = bookMakerArray[0]?.oddList[1]
  const odd3nd = bookMakerArray[0]?.oddList[2]



  useEffect(() => {
    if (odd1st) {
      const plusminusDatat1 = bookmakerPMData.filter(entry => entry.session === odd1st?.name);   
      const plusminusDatat2 = bookmakerPMData.filter(entry => entry.session === odd2nd?.name);  
      const plusminusDatat3 = bookmakerPMData.filter(entry => entry.session === odd3nd?.name); 

      const totalt3P1 = plusminusDatat3.reduce((sum, entry) => sum + entry.p1, 0);
      const totalt3P2 = plusminusDatat3.reduce((sum, entry) => sum + entry.p2, 0);
      const totalt3P3 = plusminusDatat3.reduce((sum, entry) => sum + entry.p3, 0);

      const totalt1P1 = plusminusDatat1.reduce((sum, entry) => sum + entry.p1, 0);
      const totalt1P2 = plusminusDatat1.reduce((sum, entry) => sum + entry.p2, 0);
      const totalt1P3 = plusminusDatat1.reduce((sum, entry) => sum + entry.p3, 0);

      const totalt2P1 = plusminusDatat2.reduce((sum, entry) => sum + entry.p1, 0);
      const totalt2P2 = plusminusDatat2.reduce((sum, entry) => sum + entry.p2, 0);
      const totalt2P3 = plusminusDatat2.reduce((sum, entry) => sum + entry.p3, 0);
      
      // Set the profit and loss to the total p1 sum
      setposition1(totalt1P1 + totalt2P2 + totalt3P1);
      setposition2(totalt2P1 + totalt1P2 +totalt3P2);
      setposition3(totalt1P3 + totalt2P3 + totalt3P3);

     
    }
  }, [odd1st,BookmakerBets]);

  const maxValue = Math.max(pmposition1, pmposition2, pmposition3);

  return (
    <>
      <BetSessionDialog
        open={openDialog}
        onClose={handleCancelBet}
        FancyType={fancytype}
        selectedSession={selectedSession}
        selectedRate={selectedRate}
        betMode={betMode}
        amountInput={amountInput}
        winningamount={winningamount}
        handelAmountclick={handelAmountclick}
        handleAmountChange={handleAmountChange}
        loading={loading}
        buttonValues={buttonValues}
        buttonValuesi={buttonValuesi}
        callBackSubmit={handelPlaceBet}
        callBackCancel={handleCancelBet}
      />

      <div style={{ marginTop: "10px" }}>
        <BackButton onClick={handleClick} />
      </div>
      <div className="session-container mt-2">
        <div>
          {(
            <div className="tv-section cmc">

<div>
{videoLink &&
<> 
              <a href="">
                {" "}
                <LiveTvIcon
                  sx={{
                    color: "yellow",
                    fontSize: "30px",
                    marginBottom: "5px",
                    paddingLeft: "3px",
                  }}
                />{" "}
              </a>
              <Switch
                checked={checked}
                onChange={handleToggle}
                color="default" // Prevents default blue color
                sx={{
                  "& .MuiSwitch-thumb": {
                    backgroundColor: checked ? "#5CDC00" : "gray", // Thumb color
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: checked ? "green" : "", // Track color
                  },
                  "&.Mui-checked .MuiSwitch-thumb": {
                    backgroundColor: "white", // Thumb color when checked
                  },
                }}
                label={checked ? "Yes" : "No"}
              />
              </>
}
            </div>
            <div>
           <span style={{color:'yellow'}}>Full Scorecard</span>
              <Switch
                checked={checkedfs}
                onChange={handleTogglefs}
                color="default" // Prevents default blue color
                sx={{
                  "& .MuiSwitch-thumb": {
                    backgroundColor: checkedfs ? "#5CDC00" : "gray", // Thumb color
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: checkedfs ? "green" : "", // Track color
                  },
                  "&.Mui-checked .MuiSwitch-thumb": {
                    backgroundColor: "white", // Thumb color when checked
                  },
                }}
                label={checkedfs ? "Yes" : "No"}
              />
            </div>

            </div>


          )}
          {checked && videoLink && (
            <iframe
              src={videoLink}
              width="100%"
              height="230"
            // frameborder="0"
            // allowfullscreen
            ></iframe>
          )}
     
        </div>

        {livescorecard && <MatchSummary scorelink={livescorecard} checkfs ={checkedfs} />}

        {/* <div className="score-section"></div> */}

        {bookMakerArray.length != 0 && (
          <div className="session-grid bookmakerpart">
            <div className="session-header">
              <div className="session-header-left tc hh-tx">
                MIN:100 MAX:50000
              </div>
              <div className="session-header-right hh-tx">
              
                <div>LAGAI</div>
                <div style={{ borderRight: "1px solid #ffffff" }}>KHAI</div>
              </div>
            </div>
            {/* pmposition1 === maxValue ? 'textblue' : 'textred' */}

            <div>
              <div className="session-body">
                <div className="session-body-left cmc p-2">

                  <div className="h-tx1"> {odd1st.name}</div>
                  <span  className={`h-txp1 me-2 ${ pmposition1 === 0 ? 'textgrey' : pmposition1 < 0 ? 'textred' : 'textblue'}`}><b>{roundToDecimals(pmposition1)}</b></span>

                </div>
                <div className="session-body-right">
                <div
                    className="back-section cmd"
                    onClick={() =>
                      odd1st.status !== "SUSPENDED" &&
                      odd1st.backOdds > 0 &&
                      bookMakerArray[0].type === "BookMaker" &&
                      handleSessionClick(
                        odd1st,
                        "Yes",
                        odd1st.backVolume,
                        odd1st.backOdds,
                        null,
                        bookMakerArray[0].type
                      )
                    }
                  >
                    <div className="h-tx">{odd1st.backOdds}</div>
                    {/* <span className="l-tx">{odd1st.backVolume}</span> */}
                  </div>
                  <div
                    className="lay-section cmd"
                    onClick={() =>
                      odd1st.status !== "SUSPENDED" &&
                      odd1st.layOdds > 0 &&
                      bookMakerArray[0].type === "BookMaker" &&
                      handleSessionClick(
                        odd1st,
                        "No",
                        odd1st.layVolume,
                        odd1st.layOdds,
                        null,
                        bookMakerArray[0].type,
                      )
                    }
                  >
                    <div className="h-tx">{odd1st.layOdds}</div>
                    {/* <span className="l-tx">{odd1st.layVolume}</span> */}
                  </div>

                

                </div>
              </div>
              <div className="session-body">
                <div className="session-body-left cmc p-2">
                  <div className="h-tx1"> {odd2nd.name}</div>
                  <span className={`h-txp1 me-2 ${ pmposition2 === 0 ? 'textgrey' : pmposition2 < 0 ? 'textred' : 'textblue'}`}><b>{roundToDecimals(pmposition2)}</b></span>
                </div>
                <div className="session-body-right">
                <div
                    className="back-section cmd"
                    onClick={() =>
                      odd2nd.status !== "SUSPENDED" &&
                      odd2nd.backOdds > 0 &&
                      bookMakerArray[0].type === "BookMaker" &&
                      handleSessionClick(
                        odd2nd,
                        "Yes",
                        odd2nd.backVolume,
                        odd2nd.backOdds,
                        null,
                        bookMakerArray[0].type
                      )
                    }
                  >
                    <div className="h-tx">{odd2nd.backOdds}</div>
                    {/* <span className="l-tx">{odd2nd.backVolume}</span> */}
                  </div>

                  <div
                    className="lay-section cmd"
                    onClick={() =>
                      odd2nd.status !== "SUSPENDED" &&
                      odd2nd.layOdds > 0 &&
                      bookMakerArray[0].type === "BookMaker" &&
                      handleSessionClick(
                        odd2nd,
                        "No",
                        odd2nd.layVolume,
                        odd2nd.layOdds,
                        null,
                        bookMakerArray[0].type,
                      )
                    }
                  >
                    <div className="h-tx">{odd2nd.layOdds}</div>
                    {/* <span className="l-tx">{odd2nd.layVolume}</span> */}
                  </div>

                
                  {/* {(session.status === "SUSPENDED" ||
                      session.status === "Ball Running") && (
                      <div
                        className="
          "
                      >
                        {session.status}
                      </div>
                    )} */}
                </div>
              </div>

{ odd3nd &&
              <div className="session-body tc">
                <div className="session-body-left cmc" >
                  <div className="h-tx1"> {odd3nd?.name}</div>
                  
                  <span className={`h-tx1 me-2 ${ pmposition3 === 0 ? 'textgrey' : pmposition3 < 0 ? 'textred' : 'textblue'}`}><b>{roundToDecimals(pmposition3)}</b></span>
                </div>
                <div className="session-body-right tc">
                <div
                    className="back-section cmd"
                    onClick={() =>
                      odd3nd.status !== "SUSPENDED" &&
                      odd3nd.backOdds > 0 &&
                      bookMakerArray[0].type === "BookMaker" &&
                      handleSessionClick(
                        odd3nd,
                        "Yes",
                        odd3nd.backVolume,
                        odd3nd.backOdds,
                        null,
                        bookMakerArray[0].type
                      )
                    }
                  >
                    <div className="h-tx">{odd3nd.backOdds}</div>
                    {/* <span className="l-tx">{odd3nd.backVolume}</span> */}
                  </div>

                  <div
                    className="lay-section cmd"
                    onClick={() =>
                      odd3nd.status !== "SUSPENDED" &&
                      odd3nd.layOdds > 0 &&
                      bookMakerArray[0].type === "BookMaker" &&
                      handleSessionClick(
                        odd3nd,
                        "No",
                        odd3nd.layVolume,
                        odd3nd.layOdds,
                        null,
                        bookMakerArray[0].type,
                      )
                    }
                  >
                    <div className="h-tx">{odd3nd.layOdds}</div>
                    {/* <span className="l-tx">{odd3nd.layVolume}</span> */}
                  </div>

               
              
                </div>
              </div>
}

            </div>



          </div>
        )}

        {tossArray[0]?.oddList.length != (0 || undefined) && (
          <div className="session-grid">
            <div className="session-header">
              <div className="session-header-left hh-tx tc">TOSS SESSION</div>
              <div className="session-header-right ">
                <div
                  className=""
                  style={{ borderRight: "1px solid #ffffff" }}
                >NO</div>
                <div>YES</div>
              </div>
            </div>
            {tossArray[0]?.oddList?.map((session, index) => (
              <div>
                <div className="session-body">
                  <div className="session-body-left">
                    <div className="h-tx"> {session.name}</div>
                    <span className="l-tx">Session Limit: {session.maxBetSize}</span>
                  </div>
                  <div className="session-body-right">
                    <div
                      className="lay-section"
                      style={{ borderRight: "1px solid white" }}
                    >
                      <div className="h-tx">0</div>
                      <span className="l-tx">0</span>
                    </div>

                    <div
                      className="back-section"
                      onClick={() =>
                        session.status !== "SUSPENDED" &&
                        session.backVolume !== "0" &&
                        tossArray[0].type === "Toss" &&
                        handleSessionClick(
                          session,
                          "Yes",
                          session.backVolume,
                          session.backOdds,
                          index,
                          tossArray[0].type
                        )
                      }
                    >
                      <div className="h-tx">{session.backOdds}</div>
                      <span className="l-tx">{session.backVolume}</span>
                    </div>
                    {/* {(session.status === "SUSPENDED" ||
                      session.status === "Ball Running") && (
                      <div
                        className="
            "
                      >
                        {session.status}
                      </div>
                    )} */}
                  </div>
                </div>
                {/* {selectedTossSessionIndex === index && selectedSession && (
                  <div ref={welcomeRef} className="bet-section">
                    <div style={{ fontSize: '16px', fontWeight: '600' }}>
                      SESSION:{" "}
                      <span style={{ color: "#000" }}>{selectedSession.name}</span>
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '600' }}>
                      RATE:{" "}
                      <span style={{ color: "#000" }}>
                        {selectedRate} ({betMode})
                      </span>
                    </div>
                    <div className="enterbetAmount">
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>Amount:{" "}</span>
                      <input
                        style={{}}
                        type="number"
                        onChange={handleAmountChange}
                        value={amountInput}
                        defaultValue={0}
                      />


                      <div className="button-group mt-1">
                        <ButtonGroup
                          variant="contained"
                          aria-label="Basic button group"
                        >
                          {buttonValuesi.map((btn, index) => (
                            <Button
                              sx={btn.style}
                              key={index}
                              onClick={() => handelAmountclick(btn.value)}
                            >
                              {btn.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div className="button-group mt-1 mb-2">
                        <ButtonGroup
                          variant="contained"
                          aria-label="Basic button group"
                        >
                          {buttonValues.map((btn, index) => (
                            <Button
                              sx={btn.style}
                              key={index}
                              onClick={() => handelAmountclick(btn.value)}
                            >
                              {btn.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div style={{ textAlign: 'end' }} className="mt-2">
                        <button
                          // onClick={handelPlaceBet}
                          disabled={loading}
                          style={{ backgroundColor: "#1976D2", padding: '7px 45px', border: 'none', marginRight: '10px' }}
                        >
                          Done
                        </button>

                        <button
                          // onClick={handelCancelkBet}
                          style={{ backgroundColor: "#1976D2", padding: '7px 45px', border: 'none' }}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            ))}
          </div>
        )}

        {fancyArray[0]?.oddList.length != 0 && fancyArray.length != 0 && (
          <div className="session-grid">
            <div className="session-header">
              <div className="session-header-left hh-tx tc">SESSION</div>
              <div className="session-header-right hh-tx">
                <div style={{ borderRight: "1px solid #ffffff" }}>NO</div>
                <div>YES</div>
              </div>
            </div>
            {fancyArray[0]?.oddList?.map((session, index) => (
              <div>
                <div className="session-body">
                  <div className="session-body-left">
                    <div className="h-tx"> {session.name}</div>
                    <span className="l-tx">
                      Session Limit: {session.maxBetSize}
                    </span>
                  </div>

                  <div className="session-body-right">
                    <div
                      className="lay-section"
                      style={{ borderRight: "1px solid white" }}
                      onClick={() =>
                        session.status !== "SUSPENDED" &&
                        session.layOdds !== "0" &&
                        handleSessionClick(
                          session,
                          "No",
                          session.layVolume,
                          session.layOdds,
                          index,
                          fancyArray[0].type
                        )
                      }
                    >
                      <div className="h-tx">{session?.layVolume}</div>
                      <span className="l-tx">{session.layOdds}</span>
                    </div>

                    <div
                      className="back-section"
                      onClick={() =>
                        session.status !== "SUSPENDED" &&
                        session.backOdds !== "0" &&
                        handleSessionClick(
                          session,
                          "Yes",
                          session.backVolume,
                          session.backOdds,
                          index,
                          fancyArray[0].type
                        )
                      }
                    >
                      <div className="h-tx">{session.backVolume}</div>
                      <span className="l-tx">{session.backOdds}</span>
                    </div>
                    {/* {(session.status === 'SUSPENDED' || session.status === 'Ball Running') &&
                      <div className="overlap-content">{session.status}</div>
                    } */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {(fancyArray[0]?.oddList?.length === 0 &&
        (!tossArray[0]?.oddList || tossArray[0]?.oddList.length === 0) &&
        (!bookMakerArray[0]?.oddList ||
          bookMakerArray[0]?.oddList.length === 0)) ||
        (sassiondata?.data === null && (
          <div className="nodata-img">
            <img src={nodata} />
          </div>
        ))}

        
  {TossBets && TossBets.length !== 0 && (
        <div className="fancy-bet mt-2">
          <div
            style={{
              backgroundColor: " #636062",
              color: "white",
              textAlign: "center",
              width: "100%",
              padding: "5px 0px",
              fontSize: "16px",
            }}
          >
            <h6 className="m-0">TOSS BETS</h6>
          </div>
          <div className="fancy-bet-table">
            <table border="1">
              <thead>
                <tr>
                  <th>TOSS</th>
                  <th>RUN</th>
                  <th>RATE</th>
                  <th>AMOUNT</th>
                  <th>MODE</th>
                </tr>
              </thead>
              <tbody>
                {TossBets.map((item, index) => {
                  const gamemode =
                    item.oddType === "BookMaker"
                      ? item.mode === "No"
                        ? "Khai"
                        : "Lagai"
                      : item.mode;
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "start" }}>
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          {item.session}
                        </span>
                        <br />
                      </td>
                      {/* <td>
                        <span>{item.oddType}</span>
                      </td> */}
                      <td>
                        <span>{item.run}</span>
                      </td>
                      <td>
                        <span>{item.rate}</span>
                      </td>
                      <td>
                        <span>{item.amount}</span>
                      </td>
                      <td>
                        <span>{gamemode}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}


      {BookmakerBets && BookmakerBets.length !== 0 && (
        <div className="fancy-bet mt-2">
          <div
            style={{
              backgroundColor: " #636062",
              color: "white",
              textAlign: "center",
              width: "100%",
              padding: "5px 0px",
              fontSize: "16px",
            }}
          >
            <h6 className="m-0">BOOKMAKER BETS</h6>
          </div>
          <div className="fancy-bet-table">
            <table border="1">
              <thead>
                <tr>
                  <th>BOOKMAKER</th>
                  {/* <th>TYPE</th> */}
                  {/* <th>RUN</th> */}
                  <th>RATE</th>
                  <th>AMOUNT</th>
                  <th>MODE</th>
                </tr>
              </thead>
              <tbody>
                {BookmakerBets.map((item, index) => {
                  const gamemode =
                    item.oddType === "BookMaker"
                      ? item.mode === "No"
                        ? "Khai"
                        : "Lagai"
                      : item.mode;
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "start" }}>
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          {item.session}
                        </span>
                        <br />
                      </td>
                      {/* <td>
                        <span>{item.oddType}</span>
                      </td> */}
                      {/* <td>
                        <span>{item.run}</span>
                      </td> */}
                      <td>
                        <span>{item.rate}</span>
                      </td>
                      <td>
                        <span>{item.amount}</span>
                      </td>
                      <td>
                        <span>{gamemode}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {SessionBets && SessionBets.length !== 0 && (
        <div className="fancy-bet mt-2">
          <div
            style={{
              backgroundColor: " #636062",
              color: "white",
              textAlign: "center",
              width: "100%",
              padding: "5px 0px",
              fontSize: "16px",
            }}
          >
            <h6 className="m-0">SESSION BETS</h6>
          </div>
          <div className="fancy-bet-table">
            <table border="1">
              <thead>
                <tr>
                  <th>SESSION</th>
                  <th>RUN</th>
                  <th>RATE</th>
                  <th>AMOUNT</th>
                  <th>MODE</th>
                </tr>
              </thead>
              <tbody>
                {SessionBets.map((item, index) => {
                  const gamemode =
                    item.oddType === "BookMaker"
                      ? item.mode === "No"
                        ? "Khai"
                        : "Lagai"
                      : item.mode;
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "start" }}>
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          {item.session}
                        </span>
                        <br />
                      </td>
                      {/* <td>
                        <span>{item.oddType}</span>
                      </td> */}
                      <td>
                        <span>{item.run}</span>
                      </td>
                      <td>
                        <span>{item.rate}</span>
                      </td>
                      <td>
                        <span>{item.amount}</span>
                      </td>
                      <td>
                        <span>{gamemode}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {data1 && data1.length !== 0 && (
        <div className="fancy-bet">
          <div
            style={{
              backgroundColor: "#9b999a",
              color: "white",
              textAlign: "center",
              width: "100%",
              padding: "5px 0px",
              fontSize: "16px",
            }}
          >
            <h6 className="m-0"> COMPLETED FANCY BETS </h6>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Total P/L :{" "}
              <span style={{ color: costTotal < 0 ? "red" : "blue" }}>
                {roundToDecimals(costTotal)}
              </span>
            </div>
          </div>
          <div className="fancy-bet-table">
            <table border="1">
              <tr>
                <th>RUNNER</th>
                <th>TYPE</th>
                <th>DATE</th>
                <th>RUN</th>
                <th>RATE</th>
                <th>AMOUNT</th>
                <th>MODE</th>
                <th>P&L</th>
                <th>RESULT</th>
              </tr>

              {data1.map((item, index) => {
                const newdate = item.createdDateTime;
                const utcDate = toZonedTime(new Date(newdate), "UTC");
                const formattedDate = format(utcDate, "d MMMM h:mm a");
                const gamemode =
                  item.oddType === "BookMaker"
                    ? item.mode === "No"
                      ? "Khai"
                      : "Lagai"
                    : item.mode;
                return (
                  <tr key={index}>
                    <td style={{ textAlign: "start" }}>
                      <span style={{ fontWeight: "600", fontSize: "12px" }}>
                        {item.session}
                      </span>
                      <br />
                    </td>
                    <td>
                      <span>{item.oddType}</span>
                    </td>
                    <td>
                      <span>{formattedDate}</span>
                    </td>

                    <td>
                      <span>{item.run}</span>
                    </td>
                    <td>
                      <span>{item.rate}</span>
                    </td>
                    <td>
                      <span>{item.amount}</span>
                    </td>

                    <td>
                      <span>{gamemode}</span>
                    </td>
                    <td>
                      <span>{roundToDecimals(item.profitAndLoss) || ""}</span>
                    </td>
                    <td>
                      <span>{item.result}</span>
                      <span>{item.completed}</span>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}

      <div className="mt-2">
        <BackButton onClick={handleClick} />
      </div>
    </>
  );
};
export default InplaySession;
